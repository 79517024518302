
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_STAFF, IS_ACTIVE_CLIENT, LOAD_CLIENT_LIST } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { IClientList, ICommonSearch } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader },
  name: ESmartBarcodeMenu.CLIENT,
})
export default class Client extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false
  isShowOnlyActive = true

  get getItems(): IClientList[] {
    const clientList = [...this.$store.state.client.clients].filter((val: IClientList) => {
      if (val.clientUser) {
        return val
      }
    })
    return clientList
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter(
      (client: IClientList) =>
        this.cleanString(client?.clientUser?.fullName || '').includes(cleanSearchText) ||
        this.cleanString(client?.client?.name || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.email || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.phone.number || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.accessCode || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.id || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: IClientList) => item.client.isActive === true)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_CLIENT, this.isShowOnlyActive)
    this.filterItems()
  }

  fullNameFormater(row: IClientList) {
    return `${row.clientUser?.lastName} ${row.clientUser?.firstName}`
  }

  onCreateClient() {
    this.$router.push({
      name: 'clientCreate',
    })
  }

  onEditClient(client: IClientList) {
    this.$router.push({
      name: 'clientEdit',
      params: {
        id: client.client.id,
      },
    })
  }

  async fetchList() {
    try {
      this.loading = true
      const promises = [this.$store.dispatch(LOAD_CLIENT_LIST)]
      if (this.isSetDisplayedBarcodeByClient) {
        promises.push(this.$store.dispatch(FETCH_STAFF))
      }
      await Promise.all(promises)
      this.filterItems()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async created() {
    this.routeName = 'clients'
    this.isShowOnlyActive = this.$store.state.client.isShowOnlyActive
    this.getQueryData()

    if (!this.$store.state.client.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
