<template>
  <div v-loading.fullscreen="loading" class="flex flex-col responsive-page fixed-table">
    <!-- <div class="fixed-header-container"> -->
    <div class="flex items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader :title="$t('barcode_type.client')" @load:refreshList="fetchList" />
      </div>
      <div class="flex header-container__right">
        <el-button class="mr-8" type="primary" icon="el-icon-plus" size="medium" @click="onCreateClient()">
          {{ $t('client.new_client') }}
        </el-button>

        <el-input
          class="w-60"
          prefix-icon="el-icon-search"
          v-model="dataSearch.text"
          @keyup="refreshItemList"
          @blur="updateTextSearch"
        />
      </div>
    </div>
    <!-- </div> -->

    <div v-if="displayItems.length > 0" class="flex-grow flex flex-col">
      <div class="flex justify-end table-actions py-5">
        <el-checkbox v-model="isShowOnlyActive">{{ $t('carrier.show_only_active') }}</el-checkbox>
      </div>
      <div class="mb-8 flex-grow table-container">
        <el-table
          :data="displayItems"
          class="table-content rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @row-click="onEditClient"
        >
          <el-table-column prop="client.name" :label="$t('client.client_name')" width="140" />
          <el-table-column :label="$t('client.location')" :min-width="45">
            <template v-slot="scope">
              {{ scope.row.client.address.address1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="clientUser.fullName"
            :formatter="fullNameFormater"
            :label="$t('client.contact_name')"
            width="140"
          />
          <el-table-column prop="clientUser.email" :label="$t('client.contact_email')" :min-width="40" />
          <el-table-column prop="clientUser.accessCode" :label="$t('client.access_code')" :min-width="33">
            <template v-slot="scope">
              {{ scope.row?.clientUser?.accessCode.replace(/(\d{4})(\d{4})/, '$1-$2') }}
            </template></el-table-column
          >
          <el-table-column prop="isActive" :label="$t('status')" width="100">
            <template v-slot="scope">
              {{ scope.row.client.isActive ? $t('active') : $t('inactive') }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="justify-center flex flex-row paging-container mb-16">
        <el-pagination
          layout="prev, pager, next"
          :total="totalPaging"
          :page-size="itemsPerPage"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          class="custom-pagination"
        />
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
</template>

<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_STAFF, IS_ACTIVE_CLIENT, LOAD_CLIENT_LIST } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { IClientList, ICommonSearch } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader },
  name: ESmartBarcodeMenu.CLIENT,
})
export default class Client extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false
  isShowOnlyActive = true

  get getItems(): IClientList[] {
    const clientList = [...this.$store.state.client.clients].filter((val: IClientList) => {
      if (val.clientUser) {
        return val
      }
    })
    return clientList
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter(
      (client: IClientList) =>
        this.cleanString(client?.clientUser?.fullName || '').includes(cleanSearchText) ||
        this.cleanString(client?.client?.name || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.email || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.phone.number || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.accessCode || '').includes(cleanSearchText) ||
        this.cleanString(client?.clientUser?.id || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: IClientList) => item.client.isActive === true)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_CLIENT, this.isShowOnlyActive)
    this.filterItems()
  }

  fullNameFormater(row: IClientList) {
    return `${row.clientUser?.lastName} ${row.clientUser?.firstName}`
  }

  onCreateClient() {
    this.$router.push({
      name: 'clientCreate',
    })
  }

  onEditClient(client: IClientList) {
    this.$router.push({
      name: 'clientEdit',
      params: {
        id: client.client.id,
      },
    })
  }

  async fetchList() {
    try {
      this.loading = true
      const promises = [this.$store.dispatch(LOAD_CLIENT_LIST)]
      if (this.isSetDisplayedBarcodeByClient) {
        promises.push(this.$store.dispatch(FETCH_STAFF))
      }
      await Promise.all(promises)
      this.filterItems()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async created() {
    this.routeName = 'clients'
    this.isShowOnlyActive = this.$store.state.client.isShowOnlyActive
    this.getQueryData()

    if (!this.$store.state.client.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';
.fixed-header-container {
  background-color: $body-bg;
}

.fixed-table:deep() {
  height: calc(100vh - 100px);
  min-width: 700px;
  &.table-content {
    overflow: auto;
    height: calc(100vh - 350px);
    min-height: 200px;
  }
  &.el-table {
    &::before {
      height: 0px;
    }
    &__header-wrapper {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 10;
    }
  }
}
</style>
